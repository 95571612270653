<template>
	<div class="city-main" v-if="visible">
		<div class="cityModal">
			<div class="cityHeader">
				<p>请选择可选机场！</p>
				<p>
					<span class="el-icon-close fontIcon" @click="cancel"></span>
				</p>
			</div>
			<div class="cityContent">
				<div class="header">
					<p>根据贵公司规定，您本次出行可选择以下城市:</p>
					<p>暂无城市数据可选择！请先选择出差申请单后，再选择城市</p>
				</div>
				<div class="content">
					<div class="con1">
						<!-- 机票 -->
						<div class="con1-item"   >
                            <div v-for="(item, index) in currentCityList"  :key="item.cityId">   
                                <el-radio class="itemRadio"  
                                    :label="item.airport"
                                    :value="item"
                                    v-model="chooseCity"
                                    :disabled="(item.children && item.children.length > 1) || (item.children && item.children.length > 0 && !item.airport )"
                                    @change="getSelectInfo(index,item)"
                                >
                                  <span class="city-tip">城市</span>  
                                  <span style="color: #424242;">
                                    <span>{{item.cityName }}</span>
                                    <!-- <span v-if="item.children && item.children.length > 1">(所有机场)</span> -->
                                    <span v-if="item.airportName">({{ item.airportName || '' }})</span>
                                    <span v-show="item.airport">({{ item.airport || '' }})</span>
                                  </span>
                               
                                </el-radio>
                                <div v-if="(item.children && item.children.length > 1) || (item.children && item.children.length > 0 && !item.airport )">
                                    <div class="" v-for="cItem in item.children" :key="cItem.airport">  
                                        <el-radio class="itemRadio"  
                                            :label="cItem.airport"
                                            :value="cItem"
                                            v-model="chooseCity"
                                            @change="getSelectInfo(index,cItem)"
                                        >
                                            <span class="airport-tip">机场</span>
                                            <span>{{cItem.airportName}}</span>
                                            <span v-show="cItem.airport">({{cItem.airport}})</span>
                                        </el-radio>
                                    </div>
                                </div>
                                
                            </div>
						
						</div>
					
					</div>
				</div>
			</div>
			<div class="cityFooter">
				<el-button  @click="cancel">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</div>
		</div>
	</div>
</template>

<script>

	export default{
		name: 'CityAirport',
		data() {
			return {
				city:{}, // 出差申请单所有数据
				currentCityList:[], // 当前城市数据
				paramsData: {
					cityType: 1, // 出发到达城市  机票必传
					cityId:'', // 城市id  酒店选中必传
					szm:'', // 城市三字码  机票选中行必传
					proType:10901, // 模块类型  必传
				},	

				chooseCity:'',// 选中城市
				
				cityIndex: 0, //选中城市索引

                selectCityObj:{},

			}
		},
		props:{
			/*选中的出差申请单信息*/
			ccsqdInfo:{
				type: Object,
				default(){
					return {};
				}
			},
			/*传入进来的城市信息 主要包含 城市id 三字码 及 业务类型*/
			paramsInfo:{ // 城市参数信息
				type: Object,
				default(){
					return {};
				}
			},
			/*组件的隐藏显示*/
			visible:{
				type: Boolean,
				default: false,
			},
			/*出差申请单行程控制方式*/
			changeRouteRole:{
				type: [String,Number],
				default: '0',
			},
		},
		watch:{
			/*监听初始参数变化*/
			ccsqdInfo: {
				handler(newInfo, oldInfo){
					this.city = newInfo;
					// this.$forceUpdate()
				}, 
				immediate: true,
				deep:true,	　 
			},
			visible:{
				handler(bool){
					if(bool){
						setTimeout(()=>{
							this.getCityStatus(this.ccsqdInfo,this.paramsInfo);
						},100)
					
						
					}
				},
				immediate: true,
			},
			paramsInfo:{
				handler(val){
					
					this.paramsData.cityType = val.cityType;
					this.paramsData.cityId = val.cityId;
					this.paramsData.szm = val.szm;
					this.paramsData.proType = val.proType; 
					if(val.proType == 10903){
						this.chooseCity = val.cityId;
					}else{
						this.chooseCity = val.szm;
					}
					// this.remove()
				},
				immediate: true,
				deep:true,
			},
			
		},
		created(){
			this.city = this.ccsqdInfo;
		},
		methods: {
			// 切换不同模块时，清除之前数据
			remove(){
				this.city = {};
				this.currentCityList = [];
			},
			// 选择城市
			getCityStatus(){
		
					// 获取城市信息

				if(!this.city.ccsqdId){
					return
				}
				if(this.changeRouteRole==0){
					return
				}else if(this.changeRouteRole==2){
					this.getAllCity(this.city)
				}else if(this.changeRouteRole==3){
					this.getAllCity(this.city,this.paramsData.cityType)
				}
			
			},
			// 全部城市数据
			getAllCity(city,type){
				// 出发，到达行程信息
				let allCity = []; 
				// 所有行程信息
				let cityList = [];
				// 遍历后的行程信息
				let newCityList = [];
				// 最后被使用的结果数组
				let newResultList = [];
				
				let proType = this.paramsData.proType;
				cityList = city.detail.allRouteList || [];
				
				for(var k=0;k<cityList.length;k++){
					var cityItemArray = cityList[k];
					if(this.$common.isArray(cityItemArray)){
						for(var j=0;j<cityItemArray.length;j++){
							if(cityItemArray[j]['proType'] == proType || cityItemArray[j]['proType'] == '---'){
								newCityList.push(cityItemArray[j])
							}
						}
					}
				}
				
				
				let fromCity = []; // 出发城市
				let toCity = []; // 到达城市
				newCityList.forEach((item,index)=>{
					let fromObj = {};
					let toObj = {};
					
					fromObj = {
						airport: item.departAirport,
						airportName: item.departAirportName,
						cityId: item.departCity,
						cityName: item.departCityName,
					}
					fromCity.push(fromObj);
					
					if(proType!=10903){
						toObj = {
							airport: item.arriveAirport,
							airportName: item.arriveAirportName,
							cityId: item.arriveCity,
							cityName: item.arriveCityName,
						}
						toCity.push(toObj);
					}
					
				})
					// 选择出发
					if(type==1){
						allCity = fromCity;
					// 选择到达
					}else if(type == 2){
						allCity = toCity
					// 出发和到达
					}else{
						allCity = fromCity.concat(toCity)
					}	
				
				// 中铁单点兼容问题
				// 处理兵器单点行程的 多城市问题
				if(city.detail && city.detail.totalTrip && city.detail.totalTrip['routeId'] == city.routeId){
					var ddcityList =city.detail.totalTrip.cityList || [];
					for(var k = 0;k< ddcityList.length;k++ ){
						var cityItem = ddcityList[k] || {};
						if(cityItem.productType == this.proType){
							allCity.push({
								airport: cityItem.airportCode,
								airportName:cityItem.airportName,
								cityId:cityItem.cityId,
								cityName:cityItem.cityName,
								selectType: type,
							});
						}

					}
					
				}
				
				// 去重操作
				
				allCity.forEach((a)=> {
					
				    let check = newResultList.every((b)=> {
						let bool = false;
						if(proType==10903){
							// 酒店比较用城市Id	
							bool = (a.cityId != b.cityId);
						}else{
							// 机票比较 机场三字码（会出现一个城市多个机场问题）
							bool = (a.cityId != b.cityId);
							// bool = (a.airport!=b.airport)
						}
				            return bool;
						
				        }) 
				        check ? newResultList.push(a) : ''
				}) 
				this.currentCityList = newResultList;
                
                if(this.currentCityList.length>0){
                    this.currentCityList.forEach((cityItem,cityIndex)=>{
                        this.getNextStationList(cityIndex,cityItem.cityId);
                    })
                }
                
			},

            // 获取下一城市等级的机场
            getNextStationList(cityIndex,cityId){
                if(!cityId){return false}
				var obj = {
					cityId: cityId
				};
				this.$conn.getConn('tkQuery2023.queryAirports2')(obj, (res) => {
					let cityList = res.data || [];

                    // airportName: "浦东国际机场"
                    // cityId: "10621"
                    // cityName: "上海"
                    // country: "00002"
                    // gaodeId: "310100"
                    // international: 1
                    // threeCode: "PVG"

              
                    let curItem = this.currentCityList[cityIndex];
                    cityList.map(city=>{
                        if(curItem.airport == city.threeCode){
                            curItem.airportName = city.airportName || curItem.airportName;
                        }
                    })
                    this.currentCityList[cityIndex] = curItem;

                    this.currentCityList[cityIndex].children = cityList.map(city=>{
                        return {
                            airport:city.threeCode,
							airportName:city.airportName,
							cityId:city.cityId,
							cityName:city.cityName,
							// selectType: this.selectType,
                            international:city.international,
                        }
                    });


			
					this.$forceUpdate(this.currentCityList)
				}, (err) => {

				})
            },
		
			// 当前城市下标
			getSelectInfo(index,cityObj){
              
				if(!cityObj.airport){
					return
				}
				// this.cityIndex = index; 

                this.selectCityObj = cityObj || {};
			},
			cancel(){
				this.$emit('cancel',false)
			},
			determine(){
				// var i = this.cityIndex;
				if(this.currentCityList.length==0){
					this.cancel();
					return 
				}
                let currentItem = this.selectCityObj;
				let obj = {
						cityName: currentItem.cityName, //  出发城市
						threeCode: currentItem.airport, //  出发城市三字码
						cityInput: currentItem.cityName + (currentItem.airportName? ('('+currentItem.airportName +')'):'') +'('+currentItem.airport+')', //
						airportName: currentItem.airportName, 
						cityId: currentItem.cityId,
						cityType:this.paramsData.cityType
					}
				this.$emit('determine',obj)
			}
		}
	}
</script>

<style lang="scss" scoped>


    .city-tip{
        display: inline-block;
        padding: 4px 6px;
        background-color: #0085ff;
        color: white;
        font-size: 12px;
        border-radius: 4px;
        border: none;
        line-height: 1;
        margin-right: 10px;
    }
    .airport-tip{
        display: inline-block;
        padding: 4px 6px;
        background-color:  white;
        color: #0085ff;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #0085ff;
        margin-left: 20px;
        line-height: 1;
        margin-right: 10px;
    }
	.city-main{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
		z-index: 15;
		.cityModal{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 600px;
			// height: 400px;
			background-color: #FFFFFF;
			transform: translate(-50%,-50%);
			.cityHeader{
				display: flex;
				justify-content: space-between;
				padding: 15px 20px ;
				background-color: rgba(100,100,100,0.2);
				font-size: 16px;
				font-weight: bold;
				.fontIcon{
					font-size: 18px;
					cursor: pointer;
				}
				.fontIcon:hover{
					color: #0076F5;
				}
			}
			
			.cityContent{
				min-height: 290px;
                // height: 400px;
                max-height:500px ;
				padding: 20px;
				overflow: hidden;
				.header{
					text-align: left;
					line-height: 20px;
					margin-bottom: 10px;
				}
				.content{
					min-height: 220px;
                    max-height:430px ;
					overflow-x: hidden;
					overflow-y: auto;
					background-color: rgba(200,200,200,0.2);
					
					.con1{
						width: 100%;
						text-align: left;
						padding: 10px;
						.con1-item{
							width: 100%;
                            padding-bottom: 60px;
							
							.itemRadio{
								line-height: 36px;
								width: 100%;
								padding-left: 10px;
                                
							}
							.itemRadio:hover{
								color: #FFFFFF; 
								background-color: rgba(0,0,0,0.2);
								transition: all 0.3s ease;
							}
                         
						}
					}
					
				}
			}
			.cityFooter{
                background-color: #fff;
				padding: 14px ;
			}
		}
	}
</style>
